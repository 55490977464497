$(document).ready(function() {

    FastClick.attach(document.body);

    if ($('.instIcon a').attr('href').length < 1) { $('.instIcon').hide(); }
    if ($('.fbIcon a').attr('href').length < 1) { $('.fbIcon').hide(); }


    var primaryHeight = $('#primaryNav .boxContent').outerHeight();
    $('#mobileMenuIcon').click(function() {
        $('body').addClass('mmOpen');
        $('#primaryNav').css({
            'height': primaryHeight,
        });
    });
    $('#closeIcon').click(function() {
        $('body').removeClass('mmOpen');
        $('#primaryNav').css({
            'height': 0,
        });
    })

    $('.boxServicePrev, .boxServiceNext').click(function() {
        var parentContainer = $(this).parent();
        console.log($(parentContainer).attr('class'));


        $('iframe', parentContainer).each(function() {
            jQuery(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
        });
    });

    $('.scrollToForm').click(function(ev) {
        ev.preventDefault();
        $('html, body').animate({
            scrollTop: $("#contactInfo").offset().top
        }, 500);
    });

    $('#dataCheck, #dataCheck2').val('');

    $('.checkButton').click(function(ev) {
        ev.preventDefault();
        if ($(this).hasClass('check')) {
            $(this).removeClass('check');
            $(this).next().val('');
        } else {
            $(this).addClass('check');
            $(this).next().val($(this).attr('data-check'));
        }
    });


    $('.formDataCheck').click(function(ev) {
        ev.preventDefault();

        if ($(this).hasClass('checked')) {
            $(this).removeClass('checked');
            $('#formSubmit').prop('disabled', true);
        } else {
            $(this).addClass('checked');
            $('#formSubmit').prop('disabled', false);
        }

    });

    var jgaForm = jQuery("#jgaForm").validate({
        rules: {
            formVorname: {
                required: true
            },
            formName: {
                required: true
            },
            formTag: {
                required: true
            },
            formEmail: {
                required: true,
                email: true
            }
        },
        submitHandler: function(form) {
            jQuery(form).ajaxSubmit({ target: "#formInfo" });
            jgaForm.resetForm();
            $('#dataCheck, #dataCheck2').val('');
            $('.checkButton').removeClass('check');
            setTimeout(function() {
                $('#formInfo').empty();
            }, 5000)
        }
    });

    /*
      $('.closeMovie').click(function(){
    		$(this).parent().parent().removeClass('active');
    		jQuery("iframe").each(function() {

    		  jQuery(this)[0].contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*')
    		});
    	}) */


    var swiper = new Swiper('#welcomeBlock .sliderActive', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: true,
        autoPlay: 3000,
        prevButton: '.welcomeBlockPrev',
        nextButton: '.welcomeBlockNext',
        autoplayDisableOnInteraction: false,
        pagination: '.welcomeBlockPagination',
        paginationClickable: true,
    });

    var swiper = new Swiper('.clientsSay .sliderActive', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: false,
        autoplay: 3000,
        effect: 'fade',
        autoplayDisableOnInteraction: false,
        simulateTouch: true,
        pagination: '.refPagination',
        paginationClickable: true,
    });


    var service1 = new Swiper('.boxService .s1', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: false,
        autoPlay: 3000,
        prevButton: '.boxServicePrev.s1n',
        nextButton: '.boxServiceNext.s1n',
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
    });

    var service2 = new Swiper('.boxService .s2', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: false,
        autoPlay: 3000,
        prevButton: '.boxServicePrev.s2n',
        nextButton: '.boxServiceNext.s2n',
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
    });

    var service3 = new Swiper('.boxService .s3', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: false,
        autoPlay: 3000,
        prevButton: '.boxServicePrev.s3n',
        nextButton: '.boxServiceNext.s3n',
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
    });

    var service4 = new Swiper('.boxService .s4', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: false,
        autoPlay: 3000,
        prevButton: '.boxServicePrev.s4n',
        nextButton: '.boxServiceNext.s4n',
        autoplayDisableOnInteraction: false,
        simulateTouch: false,
    });



    var swiper = new Swiper('#articles .swiper-container', {
        slidesPerView: 2,
        keyboardControl: true,
        spaceBetween: 50,
        loop: false,
        autoPlay: 3000,
        prevButton: '.articlesPrev',
        nextButton: '.articlesNext',
        pagination: '.packegesPagination',
        paginationClickable: true,
        autoplayDisableOnInteraction: false,
        simulateTouch: true,
        breakpoints: {
            767: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    });

    var swiper = new Swiper('.gallery .swiper-container', {
        slidesPerView: 1,
        keyboardControl: true,
        loop: true,
        autoPlay: 3000,
        prevButton: '.galleryBlockPrev',
        nextButton: '.galleryBlockNext',
        autoplayDisableOnInteraction: false,
        simulateTouch: true,
    });

    $('a[href*="#"]').click(function(ev) {
        ev.preventDefault();
        if (!$(this).hasClass('checkButton')) {
            var link = $(this).attr('href');
            if (link != '#') {
                var height = $(link).offset();
                var miles = (Math.round(height.top));
                $("html, body").animate({ scrollTop: miles - 94 }, 2000);

                if ($('#topNavigation').hasClass('active')) {
                    $('#topNavigation').removeClass('active');
                    $('body').removeClass('mmOpen');
                }
            }
        }
    });

    // Accordion
    $('.accordionTitle').click(function() {
        var timer = null;
        var itemAcc = $(this).parent();
        var accHeight = $(this).next().find('.accordionContentInn').height() + 40;
        if (itemAcc.hasClass('opened')) {
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            var itemToClose = $(this).next();
            $(this).next().css({ height: accHeight }).addClass('animatedAccordion');
            itemAcc.removeClass('opened');
            $(this).removeClass('accordionOpened');
            setTimeout(function() { itemToClose.css({ height: 0 }) }, 30);

        } else {
            var itemToChange = $(this).next();
            if ($(this).hasClass('accordionOpened')) {
                if (timer) {
                    clearTimeout(timer);
                    timer = null;
                }
                itemToChange.css({ height: accHeight }).addClass('animatedAccordion');
                itemToChange.css({ height: 0 });
                $(this).removeClass('accordionOpened');
                itemAcc.removeClass('opened');
            } else {
                itemToChange.css({ height: accHeight });
                $(this).addClass('accordionOpened');
                timer = setTimeout(function() {
                    itemAcc.addClass('opened');
                    itemToChange.css({ height: 'auto' }).removeClass('animatedAccordion')
                }, 330);
            }
        }
    });

    /*$(window).resize(function() {
        $('.accordionTitle').removeClass('accordionOpened').next().css({ height: 0 });
        $('.accordion').removeClass('opened');
    });*/

    $(window).scroll(function() {
        var scroll_at_top = $(window).scrollTop();
        if (scroll_at_top >= 1) {
            $('body').addClass('move');
        } else {
            $('body').removeClass('move');
        };
    });

});